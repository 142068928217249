
import * as ExcelJS from "exceljs"; // Import exceljs library
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Navbar, Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image, Font } from "@react-pdf/renderer";
//import * as XLSX from "xlsx"; // Import xlsx library
import RobotoRegular from '../fonts/Roboto-Regular.ttf';
import RobotoBold from '../fonts/Roboto-Bold.ttf';
import { RxCross2 } from "react-icons/rx";
import { useCustomerContext } from '../contexts/CustomerContext';
import "../CartPage.css"
import { FiArrowLeftCircle } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { TbListDetails } from "react-icons/tb";
import ProductRows from "./ProductRows";
import Footer from "./Footer";
// Register Fonts
Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoRegular, fontWeight: 'normal' },
    { src: RobotoBold, fontWeight: 'bold' },
  ],
});
const CartPage = () => {
  const [tabActive, setTabActive] = useState(false);
  const { state } = useLocation();
  const pdfDetails = JSON.parse(localStorage.getItem('user'));
  const cart = state?.cart || [];
  const [allCart, setAllCart] = useState([])
  console.log(allCart

  )
  const { customerDetails } = useCustomerContext();
  const navigate = useNavigate();
  const getTotalPrice = () =>
    cart.reduce((total, product) => total + product.price * product.quantity, 0);

  const handleIncrease = (productId, selectedColor, productName) => {
    const updatedCart = cart.map((product) =>
      product.id === productId &&
        product.selectedColor === selectedColor &&
        product.name === productName
        ? { ...product, quantity: product.quantity + 1 }
        : product
    );
    navigate("/cart", { state: { cart: updatedCart, customerDetails } });
  };
  const handleDecrease = (productId, selectedColor, productName) => {
    const updatedCart = cart.map((product) => {
      if (
        product.id === productId &&
        product.selectedColor === selectedColor &&
        product.name === productName
      ) {
        if (product.quantity > 1) {
          // Decrease quantity if greater than 1
          return { ...product, quantity: product.quantity - 1 };
        } else {
          // Remove product from cart if quantity is 1 or less
          return null;
        }
      }
      return product;
    }).filter(product => product !== null); // Remove null products (products that have been deleted)

    navigate("/cart", { state: { cart: updatedCart, customerDetails } });
  };
  const handleRemoveProduct = (productId, selectedColor, productName) => {
    const updatedCart = cart.filter(
      (product) =>
        !(product.id === productId &&
          product.selectedColor === selectedColor &&
          product.name === productName)
    );
    navigate("/cart", { state: { cart: updatedCart, customerDetails } });
  };

  const handleBackToProducts = () => {
    navigate("/product");
  };

  const getSubtotal = () => {
    return cart.reduce((total, product) => total + product.price * product.quantity, 0);
  };




  const gst = (getSubtotal() * (18 / 100));




  const shippingCost = getSubtotal() * 0.009;
  const tax = getSubtotal() * 0.0425;
  let addedVal = 0;
  for (let item of allCart) {
    addedVal += parseInt(item.subTotal - item.total)
  }
  let x = (addedVal / getSubtotal()) * 100
  const totalPriceWithDiscount1 = (getSubtotal() * (x / 100));
  let totalPrice = getSubtotal() + shippingCost + tax + gst - totalPriceWithDiscount1;


  const styles = StyleSheet.create({
    page: { padding: 20, fontSize: 10, fontFamily: "Roboto", display: "flex" },
    header: { marginBottom: 10, textAlign: "center" },
    logo: { width: 100, height: 50, marginBottom: 10 },
    title: {
      fontSize: 22,
      fontWeight: 'bold',
      color: '#333',
    },
    table: {
      width: '100%',
      marginBottom: 20,
      borderTop: '1px solid #ddd',
      borderBottom: '1px solid #ddd',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: '1px solid #f0f0f0',
      paddingVertical: 10,
    },
    tableHeaderRow: {
      backgroundColor: '#f2f2f2',
      fontWeight: 'bold',
    },
    headerCell: {
      width: '14%',
      textAlign: 'center',
      padding: 5,
      fontSize: 12,
    },
    cell: {
      width: '14%',
      textAlign: 'center',
      padding: 5,
      fontSize: 12,
    },
    productImage: {
      width: 80,
      height: 80,
      objectFit: 'contain',
      marginLeft: 10,
    },
    totalRow: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,
    },
    totalText: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#333',
    },
    customerDetailsTitle: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 10,
      marginTop: 30,
    },
    footer: {
      marginTop: 10,
      marginBottom: 30,
      textAlign: "right",
      fontWeight: "bold",
      fontSize: 15,
      fontFamily: "Roboto",
    },
    footerNew: {
      position: 'absolute',
      bottom: 20,
      width: '100%',
      textAlign: 'left',
      fontSize: 12,
      fontWeight: 'bold',
      color: '#333',
    },
    customerDetailsContainer: { marginTop: 20, width: "45%", float: "left", },
    customerDetailsText: { fontSize: 12, marginBottom: 3, fontWeight: 'bold', marginRight: 3 },
    customerDetailsValue: { fontSize: 12, marginLeft: 3, fontWeight: 'normal' },
    logoImage: { width: 100, height: 50, objectFit: "contain" },
    tableHeaderRow: { backgroundColor: "#f0f0f0" },
  });

  // PDF Component
  const CartPDF = ({ cart, customerDetails }) => {
    const customerInfo = [
      ["Salesperson Name:", pdfDetails.salespersonName || "N/A"],
      ["Customer Name:", pdfDetails.customerName || "N/A"],
      ["Phone:", `+${pdfDetails.phone}` || "N/A"],
      ["Email:", pdfDetails.email || "N/A"],
      ["Address:", pdfDetails.address || "N/A"],
    ];



    return (
      <Document>
        <Page style={styles.page}>
          <View style={styles.header}>
            <Image style={styles.logoImage} src="/Untitled211-rFFF.png" />
            <Text style={{ fontSize: 22, fontWeight: "bold" }}>Aquini Estimate for {pdfDetails.customerName}</Text>
          </View>

          <View style={styles.table}>
            <View style={[styles.row, styles.tableHeaderRow]}>
              <Text style={[styles.cell, styles.headerCell]}>Sr. No</Text>
              <Text style={[styles.cell, styles.headerCell]}>Image</Text>
              <Text style={[styles.cell, styles.headerCell]}>Product Name</Text>
              <Text style={[styles.cell, styles.headerCell]}>Color</Text>
              <Text style={[styles.cell, styles.headerCell]}>Washroom Name</Text>
              <Text style={[styles.cell, styles.headerCell]}>Quantity</Text>
              <Text style={[styles.cell, styles.headerCell]}>MRP</Text>
              <Text style={[styles.cell, styles.headerCell]}>SubTotal Amount</Text>
              <Text style={[styles.cell, styles.headerCell]}>Discount in %</Text>
              <Text style={[styles.cell, styles.headerCell]}>Total</Text>
            </View>

            {allCart.map((product, index) => (
              <View key={`${product.id}-${product.selectedColor || ""}-${product.name}`} style={styles.row}>
                <Text style={styles.cell}>{index + 1}</Text>
                <Image style={{ width: 80, height: 80, objectFit: "contain" }} src={product.image || ""} />
                <Text style={styles.cell}>{product.name}</Text>
                <Text style={styles.cell}>{product.selectedColor || "N/A"}</Text>
                <Text style={styles.cell}>{product.washroom}</Text>
                <Text style={styles.cell}>{product.quantity}</Text>
                <Text style={styles.cell}>{product.price}</Text>
                <Text style={styles.cell}>{product.subTotal}</Text>
                <Text style={styles.cell}>{product.discount}</Text>
                <Text style={styles.cell}>{product.total}</Text>
              </View>
            ))}
          </View>
          <View style={styles.footerNew}>
            <Text style={{ fontSize: 8 }}>
              Internal Communication: For internal partner use only
            </Text>
          </View>
          <Text style={styles.footer}>
            Total: ₹{totalPrice.toLocaleString('en-IN')}
          </Text>
          <View style={styles.table}>
            <View style={[styles.row, styles.tableHeaderRow]}>
              <Text style={[styles.cell, styles.headerCell, { flex: 1 }]}>Sr. No</Text>
              <Text style={[styles.cell, styles.headerCell, { flex: 3 }]}>WashRoom Name</Text>
              <Text style={[styles.cell, styles.headerCell, { flex: 2 }]}>MRP</Text>
            </View>
            {allCart.map((product, index) => (
              <View key={`${product.id}-${product.selectedColor || ""}-${product.name}`} style={styles.row}>
                <Text style={[styles.cell, styles.headerCell, { flex: 1 }]}>{index + 1}</Text>
                <Text style={[styles.cell, styles.headerCell, { flex: 3 }]}>{product.washroom}</Text>
                <Text style={[styles.cell, styles.headerCell, { flex: 2 }]}>{product.subTotal}</Text>
              </View>
            ))}
            <View style={[styles.row, { borderTopWidth: 1, borderTopColor: 'black' }]}>
              <Text style={[styles.cell, { flex: 4, textAlign: 'right', fontWeight: 'bold' }]}>Total</Text>
              <Text style={[styles.cell, { flex: 2, fontWeight: 'bold' }]}>
                ₹ {allCart.reduce((total, product) => total + product.subTotal, 0).toLocaleString('en-IN')}
              </Text>
            </View>
          </View>
          <View style={styles.customerDetailsContainer}>
            <Text style={{ fontSize: 14, fontWeight: "bold" }}>Customer Details</Text>
            {customerInfo.map(([label, value], index) => (
              <Text key={index} >
                <Text style={styles.customerDetailsText}>{label}</Text><Text style={styles.customerDetailsValue}>{value}</Text>
              </Text>
            ))}
          </View>
          <View style={styles.customerDetailsContainer}>
            <Image style={styles.logoImage} src="/Untitled211-rFFF.png" />
          </View>
        </Page>
      </Document>
    );
  };
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Cart Items");

    const headers = [
      "Sr. No",
      "Image",
      "Product Name",
      "Color",
      "WashRoom Name",
      "Quantity",
      "Price",
      "SubTotal",
      "Discount in %",
      "Total",
    ];

    worksheet.columns = [
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 15 },
      { width: 30 },
      { width: 10 },
      { width: 15 },
      { width: 10 },
      { width: 30 },
      { width: 15 },
    ];

    const headerRow = worksheet.addRow(headers);
    headerRow.height = 25;

    headerRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.font = { bold: true };
    });

    let grandTotal = 0;

    for (const [index, product] of allCart.entries()) {
      const row = worksheet.addRow([
        index + 1,
        "",
        product.name,
        product.selectedColor || "N/A",
        product.washroom,
        product.quantity,
        product.price,
        product.subTotal,
        product.discount,
        product.total,
      ]);
      row.height = 80;

      row.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

      if (product.image) {
        const response = await fetch(product.image);
        const buffer = await response.arrayBuffer();
        const imageId = workbook.addImage({
          buffer,
          extension: "jpeg",
        });

        const cellWidth = worksheet.columns[1].width * 7.5;
        const cellHeight = row.height;
        const imageWidth = 50;
        const imageHeight = 50;

        const colStart = 1;
        const rowStart = index + 1;


        const xOffset = (cellWidth - imageWidth) / 2 + 21.5;
        const yOffset = (cellHeight - imageHeight) / 2 - 9;

        worksheet.addImage(imageId, {
          tl: { col: colStart + xOffset / 72, row: rowStart + yOffset / 20 },
          ext: { width: imageWidth, height: imageHeight },
        });

        const imageCell = worksheet.getCell(`B${index + 2}`);
        imageCell.alignment = {
          vertical: "bottom",
          horizontal: "center",
          wrapText: true,
        };
      }
      grandTotal += product.total;
    }

    const grandTotalRow = worksheet.addRow([
      "", "", "", "", "", "", "", "", "Grand Total:", grandTotal
    ]);
    grandTotalRow.eachCell((cell) => {
      cell.alignment = { vertical: "middle", horizontal: "center" };
      if (cell.value === "Grand Total:") {
        cell.font = { bold: true };
      }
    });

    worksheet.addRow([]);
    const customerRow = worksheet.addRow(["Customer Details"]);
    customerRow.font = { bold: true };

    const customerInfo = [
      ["Salesperson Name:", pdfDetails.salespersonName || "N/A"],
      ["Customer Name:", pdfDetails.customerName || "N/A"],
      ["Phone:", `+${pdfDetails.phone}` || "N/A"],
      ["Email:", pdfDetails.email || "N/A"],
      ["Address:", pdfDetails.address || "N/A"],
    ];

    customerInfo.forEach(([label, value]) => {
      const detailRow = worksheet.addRow([label, value]);
      detailRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "left" };

      });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Aquini-Estimate.xlsx";
      link.click();
    });
  };

  //   const downloadExcel = async () => {
  //     const workbook = new ExcelJS.Workbook();
  //     const worksheet = workbook.addWorksheet("Cart Items");

  //     const headers = [
  //       "Sr. No",
  //       "Image",
  //       "Product Name",
  //       "Color",
  //       "WashRoom Name",
  //       "Quantity",
  //       "Price",
  //       "SubTotal",
  //       "Discount in %",
  //       "Total",
  //     ];

  //     worksheet.columns = [
  //       { width: 20 },
  //       { width: 20 },
  //       { width: 30 },
  //       { width: 15 },
  //       { width: 30 },
  //       { width: 10 },
  //       { width: 15 },
  //       { width: 10 },
  //       { width: 30 },
  //       { width: 15 },
  //     ];

  //     const headerRow = worksheet.addRow(headers);
  //     headerRow.height = 25;

  //     headerRow.eachCell((cell) => {
  //       cell.alignment = { vertical: "middle", horizontal: "center" };
  //       cell.font = { bold: true };
  //     });

  //     let grandTotal = 0; // Initialize grand total

  //     for (const [index, product] of allCart.entries()) {
  //       const row = worksheet.addRow([
  //         index + 1,
  //         "",
  //         product.name,
  //         product.selectedColor || "N/A",
  //         product.washroom,
  //         product.quantity,
  //         product.price,
  //         product.subTotal,
  //         product.discount,
  //         product.total,
  //       ]);
  //       row.height = 80;

  //       row.eachCell((cell) => {
  //         cell.alignment = { vertical: "middle", horizontal: "center" };
  //       });

  //       if (product.image) {
  //         const response = await fetch(product.image);
  //         const buffer = await response.arrayBuffer();
  //         const imageId = workbook.addImage({
  //           buffer,
  //           extension: "jpeg",
  //         });

  //         const cellWidth = worksheet.columns[1].width * 7.5;
  //         const cellHeight = row.height;
  //         const imageWidth = 50;
  //         const imageHeight = 50;

  //         const colStart = 1;
  //         const rowStart = index + 1;

  //         const xOffset = (cellWidth - imageWidth) / 2 + 21.5;
  //         const yOffset = (cellHeight - imageHeight) / 2 - 9;

  //         worksheet.addImage(imageId, {
  //           tl: { col: colStart + xOffset / 72, row: rowStart + yOffset / 20 },
  //           ext: { width: imageWidth, height: imageHeight },
  //         });

  //         const imageCell = worksheet.getCell(`B${index + 2}`);
  //         imageCell.alignment = {
  //           vertical: "bottom",
  //           horizontal: "center",
  //           wrapText: true,
  //         };
  //       }

  //       grandTotal += product.total; // Accumulate the total
  //     }

  //     // Add a row for the grand total
  //     const grandTotalRow = worksheet.addRow([
  //       "", "", "", "", "", "", "", "", "Grand Total:", grandTotal
  //     ]);
  //     grandTotalRow.eachCell((cell) => {
  //       cell.alignment = { vertical: "middle", horizontal: "center" };
  //       if (cell.value === "Grand Total:") {
  //         cell.font = { bold: true };
  //       }
  //     });

  //     worksheet.addRow([]);
  //     const customerRow = worksheet.addRow(["Customer Details"]);
  //     customerRow.font = { bold: true };

  //     const customerInfo = [
  //       ["Salesperson Name:", pdfDetails.salespersonName || "N/A"],
  //       ["Customer Name:", pdfDetails.customerName || "N/A"],
  //       ["Phone:", `+${pdfDetails.phone}` || "N/A"],
  //       ["Email:", pdfDetails.email || "N/A"],
  //       ["Address:", pdfDetails.address || "N/A"],
  //     ];

  //     customerInfo.forEach(([label, value]) => {
  //       const detailRow = worksheet.addRow([label, value]);
  //       detailRow.eachCell((cell) => {
  //         cell.alignment = { vertical: "middle", horizontal: "left" };
  //       });
  //     });

  //     workbook.xlsx.writeBuffer().then((buffer) => {
  //       const blob = new Blob([buffer], { type: "application/octet-stream" });
  //       const link = document.createElement("a");
  //       link.href = URL.createObjectURL(blob);
  //       link.download = "Aquini-Estimate.xlsx";
  //       link.click();
  //     });
  // };


  const handleTabActive = () => {
    setTabActive(prev => !prev)
  }

  // console.log(allCart)
  return (
    <>
      <Navbar expand="lg" className="custom-navbar shadow-sm bg-dark border-0 fixed-top px-4" variant="secondary">
        <Container fluid>
          <Navbar.Brand href="#">
            <img src="/Untitled211-rFFF.png" alt="Logo" className="product-head-logo" />
          </Navbar.Brand>

          <button className="back-wrapper" onClick={handleBackToProducts}>
            <FiArrowLeftCircle /> Back
          </button>
        </Container>
      </Navbar>
      <section className="cart-page-wrap">

        {cart.length === 0 ? (
          <div className="empty-cart-page">
            <Col md={12} className="d-flex justify-content-center align-items-center mb-3" style={{ height: "400px" }}>
              <img src="/Grouppp.png" alt="grp" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
            </Col>
            <div className="d-flex justify-content-center align-items-center">
              <h2 className="cart-head" style={{ font: "Bold" }}>No Product selected</h2>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <h4 className="cart-subhead">Try Adding some Products</h4>
            </div>
            <Col md={12} className="d-flex justify-content-center align-items-center mt-3">
              <button className="login-btn" style={{ fontSize: "16px" }} onClick={handleBackToProducts}>
                CONTINUE ADDING PRODUCTS
              </button>
            </Col>
          </div>
        ) : (
          <>
            <div className={`cart-info-wrap ${tabActive ? "expand-table" : "small-table"}`}>

              <div className="product-cart-details">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <button className="cart-back-btn" onClick={handleBackToProducts}><IoIosArrowBack /> Back to Store</button>
                  <span className="open-summary" onClick={handleTabActive}><TbListDetails fontSize={28} /> Summary</span>
                </div>
                <h1 className="cart-page-heading mb-3" style={{ font: "Bold" }}>Products Selected</h1>
                <div className="cart-product-table">
                  <table className="table">
                    <thead className="">
                      <tr>
                        <th>Items</th>
                        <th className="cart-product-name">Product Name</th>
                        <th>Color</th>
                        <th>WashRoom Name</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>SubTotal</th>
                        <th>Discount in %</th>
                        <th>Total</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map((product, index) => (
                        <ProductRows product={product}
                          index={index}
                          handleDecrease={handleDecrease}
                          handleIncrease={handleIncrease}
                          handleRemoveProduct={handleRemoveProduct}
                          setAllCart={setAllCart}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="cart-summary" >
                <span className="cart-summary-close-btn" onClick={handleTabActive}><IoClose fontSize={28} /></span>
                <div className="cart-body">
                  <h5 className="cart-title">Summary</h5>
                  <ul className="cart-list-group list-group-flush p-0">
                    <li className="cart-list-group-item mb-3 d-flex justify-content-between">
                      <span>Subtotal</span>
                      <span className="cart-summary-amount"> ₹{getSubtotal().toFixed(2)}</span>
                    </li>
                    <li className="cart-list-group-item mb-3 d-flex justify-content-between">
                      <span>Shipping</span>
                      <span className="cart-summary-amount"> ₹{shippingCost.toFixed(2)}</span>
                    </li>
                    <li className="cart-list-group-item mb-3 d-flex justify-content-between">
                      <span>Tax</span>
                      <span className="cart-summary-amount"> ₹{tax.toFixed(2)}</span>
                    </li>

                    <li className="cart-list-group-item mb-3 d-flex justify-content-between">
                      <span>GST</span>
                      <span className="cart-summary-amount"> ₹{gst.toFixed(2)}</span>
                    </li>

                    <li className="cart-list-group-item mb-3 d-flex justify-content-between align-items-center">
                      <span>Total Discount <br />Percentage</span>

                      <div className="input-container" style={{ position: "relative", display: "inline-block" }}>
                        {/* <input
                          className="green-input"
                          type="text"
                          value={`${discount}%`}
                          onChange={handleDiscountChange}
                          style={{ width: "50px", marginLeft: "2px" }}
                        /> */}
                        <p>{x.toFixed(2)}%</p>
                      </div>
                      {/* <span>{totalPriceDis.toFixed(2)}</span> */}
                      <span className="cart-summary-amount green-tex">₹{totalPriceWithDiscount1.toFixed(2)}</span>

                    </li>
                    <li className="cart-list-group-item cart-total-amount-wrap d-flex justify-content-between">
                      <span>Total</span>
                      {/* <span>${totalPriceWithDiscount.toFixed(2)}</span> */}
                      <span className="cart-summary-amount"> ₹{totalPrice.toLocaleString(2)}</span>
                    </li>
                  </ul>
                  <div className="mt-4 d-flex align-items-center justify-content-center gap-3 flex-wrap">
                    <PDFDownloadLink
                      document={<CartPDF cart={cart} customerDetails={customerDetails} />}
                      fileName="Aquini-Estimate.pdf"
                    >
                      {({ loading }) => (
                        <button className="login-btn pdf-btn">{loading ? "Preparing PDF..." : "Download PDF"}</button>
                      )}
                    </PDFDownloadLink>
                    <button className="login-btn excel-btn" onClick={downloadExcel}>
                      Download as Excel
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </>
        )}
        <Footer />
      </section>
    </>
  );
};

export default CartPage;





