import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { TiHeartFullOutline } from "react-icons/ti";

const ProductCard = ({ product, addToCart, removeFromCart, colorCodeMap }) => {
  const [isInCart, setIsInCart] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(product?.variants?.[0]?.price || 0); // Default price for the first color variant
  const [currentColor, setCurrentColor] = useState(product?.variants?.[0]?.colour || ''); // Default color for the first variant
  const [isFavorite, setIsFavorite] = useState(false); // State for favorite heart icon

  // Handle when a color is clicked
  const handleColorChange = (color, price) => {
    setCurrentColor(color);
    setCurrentPrice(price);
  };

  const handleClick = () => {
    if (isInCart) {
      removeFromCart(product.id, currentColor);
    } else {
      addToCart(product, currentColor, currentPrice);
    }
    setIsInCart(!isInCart);
  };

  const handleFavoriteClick = () => {
    setIsFavorite(!isFavorite); // Toggle heart icon color (favorite state)
  };

  const slicedProductName = product?.name?.length > 25 ? `${product?.name?.slice(0, 50)}...` : product?.name;

  return (
    <div className="product-card-wrapper position-relative">
      {/* <div className="product-body"> */}
      <div className='product-img'>
        <img src={product?.image || "default-image.jpg"} alt={product?.name} />
      </div>
      <div className='product-description'>
        <div className="product-title ">
          {slicedProductName}
        </div>
        <div className='product-price'>Price: ₹{currentPrice}</div>
        <div className='product-color-wrap' >Color: {currentColor}</div>
        <div className="product-color">
          {(product?.variants || []).map((variant, index) => (
            <React.Fragment key={index}>
              {variant.colour && (
                <Button
                  variant="light"
                  className='inner_color_wrap'
                  style={{
                    backgroundColor: colorCodeMap[variant.colour.toLowerCase()],
                    borderColor: currentColor === variant.colour ? 'black' : '#7777777a',
                    boxSizing: 'border-box',
                  }}
                  onClick={() => handleColorChange(variant.colour, variant.price)} // Update on click
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      {/* Heart icon in the top-right corner */}
      <TiHeartFullOutline size={24} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', color: isFavorite ? '#eeab00' : '#D9D9D9', borderRadius: '50%', filter: "drop-shadow(rgba(0, 0, 0,0.4) 2px 4px 6px)" }}
        onClick={handleFavoriteClick} />


      <button
        className={`add-cart-btn ${isInCart ? "active" : ""}`}
        // variant={isInCart ? "danger" : "primary"}
        onClick={handleClick}
      >
        {isInCart ? "Remove" : "Add"}
      </button>
      {/* </div> */}
    </div>
  );
};

export default ProductCard;


