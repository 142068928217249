import React, { createContext, useContext, useState } from "react";

// Create the context
const CustomerContext = createContext();

// Custom hook to use customer context
export const useCustomerContext = () => {
  return useContext(CustomerContext);
};

// Context Provider Component
export const CustomerProvider = ({ children }) => {
  const [customerDetails, setCustomerDetails] = useState({});

  const updateCustomerDetails = (details) => {
    setCustomerDetails(details);
  };

  return (
    <CustomerContext.Provider value={{ customerDetails, updateCustomerDetails }}>
      {children}
    </CustomerContext.Provider>
  );
};
