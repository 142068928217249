import React, { useState, useEffect } from "react";
import { Container, Row, Col, Navbar, Button, Badge, Spinner } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import ProductCard from "./ProductCard";
import { useCustomerContext } from '../contexts/CustomerContext';
import { IoCartSharp } from "react-icons/io5";
import { FiArrowLeftCircle } from "react-icons/fi";
import "./ProductPage.css";
import { BiSkipPrevious } from "react-icons/bi";
import { MdSkipNext } from "react-icons/md";
import Footer from "./Footer";

const colorCodeMap = {
  "chrome": "#b9b9b9",
  "ips": "#9b9b9b",
  "matt black": "#2e2e2e",
  "gun metal": "#414a51",
  "champagne gold": "#e1c59a",
  "rose gold": "#b76e79",
  "gun metal black": "#3c3e44",
  "brushed nickel": "#9c9c9c",
  "brushed gold": "#f2d36f",
  "brushed rose gold": "#d7a7a3",
  "brushed gun metal": "#6f6f6f",
  "matt black(pvd)": "#292929"
};

const ProductPage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();
  const { customerDetails } = useCustomerContext();
  const [loading, setLoading] = useState(true);
  const [colors, setColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 30;

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get("https://agencytik.io/backend/all-data");
        const fetchedProducts = Object.values(response.data.products || {});
        setProducts(fetchedProducts);
        setFilteredProducts(fetchedProducts);

        const uniqueColors = [
          ...new Set(fetchedProducts.flatMap(product => product.variants.map(variant => variant.colour)))
        ];
        setColors(uniqueColors);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();

    axios.get("https://agencytik.io/backend/categories")
      .then((response) => {
        const fetchedCategories = response.data.All_Products || [];
        setCategories(fetchedCategories.filter(category => category.isdeleted === "0"));
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  const addToCart = (product, color, price) => {
    setCart([...cart, { ...product, selectedColor: color, price, quantity: 1 , washroom_name: product.washroom_name ,Sub_total :product.Sub_total ,discount: product.discount}]);
  };

  const removeFromCart = (productId, color) => {
    const newCart = [...cart];
    const itemIndex = newCart.findIndex(item => item.id === productId && item.selectedColor === color);

    if (itemIndex >= 0) {
      newCart[itemIndex].quantity -= 1;

      if (newCart[itemIndex].quantity === 0) {
        newCart.splice(itemIndex, 1);
      }
    }

    setCart(newCart);
  };

  const handleCartClick = () => {
    navigate("/cart", { state: { cart, customerDetails } });
  };

  const handleBackClick = () => {
    navigate("/");
  };

  const handleFilterCategory = (categoryId) => {
    const filtered = products.filter(product => product.cat_id === categoryId);
    setFilteredProducts(filtered);
  };

  const handleFilterColor = (color) => {
    const filtered = products.filter(product =>
      product.variants.some(variant => variant.colour === color)
    );
    setFilteredProducts(filtered);
  };

  const resetFilters = () => {
    setFilteredProducts(products);
    setSelectedColor(null);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getCurrentPageProducts = () => {
    const startIndex = (currentPage - 1) * productsPerPage;
    const endIndex = startIndex + productsPerPage;
    return filteredProducts.slice(startIndex, endIndex);
  };
  const renderPagination = () => {
    const paginationButtons = [];

    // If current page is less than or equal to 5, show first 5 pages
    if (currentPage <= 5) {
      for (let i = 1; i <= Math.min(5, totalPages); i++) {
        paginationButtons.push(
          <button key={i} className={`pagination-btn product-page-btn ${currentPage === i ? 'active' : ''}`} onClick={() => handlePageChange(i)}>
            {i}
          </button>,
          // <span key="dots" className="pagination-dots">...</span>
        );
      }
    } else {
      paginationButtons.push(
        <button key={1} className={`pagination-btn product-page-btn ${currentPage === 1 ? 'active' : ''}`} onClick={() => handlePageChange(1)}>
          1
        </button>,
        <span key="dots" className="pagination-dots">...</span>
      );

      // Show 5th page, next and the forward arrow
      paginationButtons.push(
        <button key={5} className={`pagination-btn product-page-btn ${currentPage === totalPages ? 'active' : ''}`} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </button>
      );
    }

    // Always show next and previous arrows
    paginationButtons.unshift(
      <button className="product-page-btn" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} key="prev">
        <BiSkipPrevious style={{ fontSize: "20px" }} />
      </button>
    );
    paginationButtons.push(
      <button className="product-page-btn" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} key="next">
        <MdSkipNext style={{ fontSize: "20px" }} />
      </button>
    );

    return paginationButtons;
  };

  if (loading) {
    return (
      <div className="loader-container d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="product-page-container">
      <Navbar expand="lg" className="custom-navbar shadow-sm bg-dark border-0 fixed-top px-4" variant="secondary">
        <Container fluid>
          <Navbar.Brand href="#">
            <img src="/Untitled211-rFFF.png" alt="Logo" className="product-head-logo" />
          </Navbar.Brand>
          <button className="back-wrapper" onClick={handleBackClick}>
            <FiArrowLeftCircle /> Back
          </button>
        </Container>
      </Navbar>

      <div className="banner">
        <img src="/Banner.png" alt="Banner" className="img" />
      </div>
      <div className="container-fluid">
        <Row className="">
          <Navbar bg="light" variant="light" className="ps-4 pe-5 py-3 d-flex align-items-center justify-content-between">
            <Navbar.Brand className="product-page-head">Product Page</Navbar.Brand>
            <button className="product-page-cart" onClick={handleCartClick}>
              <IoCartSharp />
              <span className="badge-count">{cart.length}</span>
            </button>
          </Navbar>

          <div className="col-lg-3 p-0">
            <Sidebar
              categories={categories}
              colors={colors}
              colorCodeMap={colorCodeMap}
              handleFilterCategory={handleFilterCategory}
              resetFilters={resetFilters}
              handleFilterColor={handleFilterColor}
              selectedColor={selectedColor}
            />
          </div>

          <div className="col-lg-9">
            <div className="row">
              {getCurrentPageProducts().length > 0 ? (
                getCurrentPageProducts().map((product) => (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 p-0" key={`${product.id}-${selectedColor || 'default'}`}>
                    <ProductCard
                      product={product}
                      addToCart={addToCart}
                      removeFromCart={removeFromCart}
                      selectedColor={selectedColor}
                      setSelectedColor={setSelectedColor}
                      colorCodeMap={colorCodeMap}
                    />
                  </div>
                ))
              ) : (
                <Col>
                  <p>No products available in this category or color</p>
                </Col>
              )}
            </div>

            {/* Pagination controls */}
            <div className="pagination-container product-page-wrap">
              {renderPagination()}
            </div>
          </div>
        </Row>
      </div>
      </div>
      <Footer/>
    </>
  );
};

export default ProductPage;


