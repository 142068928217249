import React from "react";
import { Accordion } from "react-bootstrap";
import { TiArrowSortedDown } from "react-icons/ti";

const Sidebar = ({ categories = [], colors = [], handleFilterCategory, colorCodeMap, handleFilterColor, resetFilters, selectedColor }) => {
  return (
    <div className="sidebar">
      <div className="d-flex justify-content-center align-items-center sidebar-filter-head">
        <h5>Filters</h5>
        <div className="filter-reset">
        <img
          src="/Group1.png"
          alt="reset filters"
          className="img-fluid"
          style={{ cursor: 'pointer', width: '24px', height: '24px' }}
          onClick={resetFilters}
        />
        </div>
      </div>
      <div className="sidebar-filter-gallery">
      <Accordion defaultActiveKey={['0']} alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header><span><TiArrowSortedDown style={{marginRight:"5px", fontSize:"24px"}}/></span> Filter by Category</Accordion.Header>
        <Accordion.Body>
          <ul className="p-0">
            {categories.length > 0 ? (
                categories.map((category) => (
                  <li
                  className="filter-list"
                    key={category.id}
                    onClick={() => handleFilterCategory(category.id)} // Use category ID to filter products
                  >
                    {category.category_name}
                  </li>
                ))
              ) : (
                <li disabled>Loading...</li>
              )}
            </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><span><TiArrowSortedDown style={{marginRight:"5px", fontSize:"24px"}}/></span> Filter by Color</Accordion.Header>
        <Accordion.Body>
          <ul className="p-0">
            {colors.length > 0 ? (
              colors.map((color) => (
                <li
                className="filter-list"
                  key={color}
                  onClick={() => handleFilterColor(color)}
                >
                  {color}
                </li>
              ))
            ) : (
              <li disabled>Loading...</li>
            )}
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
      </div>
    </div>
  );
};

export default Sidebar;






