import React from 'react'

export default function Footer() {
  return (
    <div className="app-footer">
            <div className="footer-content">
                <p className='text-center m-0'>© 2025, All Rights Reserved to <span><a href="#">Aquini</a></span>. Developed and Maintained by <span><a href="https://www.vega6.com/" target='_blank'>Vega6</a></span></p>
            </div>
    </div>
  )
}
