import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "../CustomerForm.css"; // For any custom styling
import { useNavigate } from "react-router-dom";
import { useCustomerContext } from "../contexts/CustomerContext";
import logo from "../assets/images/web-logo.png";
import { FaUser } from "react-icons/fa";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { FaUserTie } from "react-icons/fa";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Footer from "./Footer";

const CustomerForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { updateCustomerDetails } = useCustomerContext();
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState("");

  // Handle phone number change
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    setValue("phone", `+91${value}`);
  };

  const onSubmit = (data) => {
    console.log("Form Data Submitted:", data);
    updateCustomerDetails(data);
    localStorage.setItem('user',JSON.stringify(data))
    navigate("/product");
  };

  return (
    <>
    <section className="login-wrapper">
      <div className="login-left-side">
        <div className="web-logo">
          <img src={logo} />
        </div>
        <div className="logo-head">
          <h1 className="mb-3">
            Customer
            <br />
            Detail Form
          </h1>
        </div>
      </div>

      <div className="login-form-wrap">
        <form onSubmit={handleSubmit(onSubmit)} className="w-100 needs-validation login-form">
          {/* Salesperson Name */}
          <div className="input-wrap mb-4">
            <label>Salesperson Name</label>
            <div className="d-flex align-items-center w-100">
              <span className="input-icon">
                <FaUserTie />
              </span>
              <input
                type="text"
                className={`login-input ${errors.salespersonName ? "is-invalid" : ""}`}
                {...register("salespersonName", { required: "Name is required" })}
                placeholder="Enter salesperson name"
              />
              {errors.salespersonName && (
                <div className="invalid-feedback">{errors.salespersonName.message}</div>
              )}
            </div>
          </div>

          {/* Customer Name */}
          <div className="input-wrap mb-4">
            <label>Customer Name</label>
            <div className="d-flex align-items-center w-100">
              <span className="input-icon">
                <FaUser />
              </span>
              <input
                type="text"
                className={`login-input ${errors.customerName ? "is-invalid" : ""}`}
                {...register("customerName", { required: "Name is required" })}
                placeholder="Enter customer name"
              />
              {errors.customerName && (
                <div className="invalid-feedback">{errors.customerName.message}</div>
              )}
            </div>
          </div>
          
          <div className="input-wrap mb-4">
            <label>Phone</label>
            <div className="d-flex align-items-center w-100">
              {/* <span className="input-icon">
                <FaPhone />
              </span> */}
              <ReactPhoneInput className="login-input p-0"
                country={"in"} 
                value={phoneNumber}
                onChange={(value) => {
                  setPhoneNumber(value);
                  setValue("phone", value); 
                }}
                inputStyle={{
                  border: "none",
                  background: "transparent",
                  color: "#333",
                  fontSize: "15px",
                  fontWeight: "500",
                  width: "100%",
                  outline: "none",
                  paddingLeft: "40px",
                  marginTop: "4px",
                }}
                isValid={(value) => value.length === 13}
              />
            </div>
            {errors.phone && <div className="invalid-feedback">{errors.phone.message}</div>}
          </div>

          {/* Email */}
          <div className="input-wrap mb-4">
            <label>Email</label>
            <div className="d-flex align-items-center w-100">
              <span className="input-icon" style={{ position: "relative", top: "1px" }}>
                <IoIosMail fontSize={22} />
              </span>
              <input
                type="email"
                className={`login-input ${errors.email ? "is-invalid" : ""}`}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
                placeholder="Enter email"
              />
              {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
            </div>
          </div>

          {/* Address */}
          <div className="input-wrap mb-4">
            <label>Address (Optional)</label>
            <div className="d-flex align-items-start w-100">
              <span className="input-icon">
                <FaLocationDot />
              </span>
              <textarea
                //className="login-input"
                className={`login-input ${errors.customerName ? "is-invalid" : ""}`}
                {...register("address")}
                placeholder="Enter address"
              />
              {errors.address && <div className="invalid-feedback">{errors.address.message}</div>}
            </div>
          </div>

          <button type="submit" className="login-btn">
            Get a Quote Now
          </button>
        </form>
      </div>
    </section>
    <Footer/>
    </>
  );
};

export default CustomerForm;
