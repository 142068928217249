import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProductPage from "./components/ProductPage";
import CartPage from "./components/CartPage";
import FormPage from "./components/FormPage";
import { CustomerProvider } from "./contexts/CustomerContext";
import './dev.css'

const App = () => {

  return (
    <CustomerProvider>
      <Router>
        <Routes>
          <Route path="/" element={<FormPage />} />
          <Route path="/product" element={<ProductPage />} />
          <Route path="/cart" element={<CartPage />} />
        </Routes>
      </Router>
    </CustomerProvider>
  );
};

export default App;


// convert this whole aove code  Application in react-redux with correct and required action and reducer with correct stores . Make the full Production code so that we can get the same output as we get know . make all the required components and files that are neede with correct code :   

// update the whole redux code for Productpage and sidebar component