import React, { useEffect, useState } from 'react'
import { RxCross2 } from 'react-icons/rx';

const ProductRows = ({ product, handleDecrease, handleIncrease, handleRemoveProduct, setAllCart , index}) => {
    const [discount1, setDiscount1] = useState(0);
    const [washroom, setWashroom] = useState("")
    const handleDiscountChangeNew = (event) => {
        const { name, value } = event.target;
        if (name === "washRoomName") {
            setWashroom(value)
        }
        else {
            setDiscount1(value);
        }
    };

    const getSubtotal1 = () => {
        return product.price * product.quantity;
    };
    const totalPrice1 = getSubtotal1() - (getSubtotal1() * (discount1 / 100));

    const prd = {
        ...product,
        washroom,
        discount: discount1,
        subTotal: getSubtotal1(),
        total:totalPrice1
    };

    useEffect(() => {
        setAllCart((prevCart) => {
            const existingProductIndex = prevCart.findIndex((val,i) => i === index);
            if (existingProductIndex !== -1) {
                const updatedCart = [...prevCart];
                updatedCart[existingProductIndex] = prd;
                return updatedCart;
            } else {
                return [...prevCart, prd];
            }
        });
    }, [product, washroom, discount1]);

    return (
        <tr key={product.id}>
            <td className="item-img">
                <img
                    src={product.image || "default-image.jpg"}
                    alt={product.name}
                    style={{ width: "150px", marginRight: "10px" }}
                />
            </td>
            <td className="cart-product-name">{product.name}</td>
            <td>{product.selectedColor || "Not selected"}</td>

            <td>
                <input
                    type="text"
                    name="washRoomName"
                    className="washRoomInput"
                    value={washroom}
                    onChange={handleDiscountChangeNew}
                    id="washRoomName" />
            </td>
            <td>
                <div className="cart-qty text-center">
                    <button className="qty-btn" onClick={() => handleDecrease(product.id, product.selectedColor, product.name)} >-</button>
                    <span className="qty-number">{product.quantity}</span>
                    <button className="qty-btn" onClick={() => handleIncrease(product.id, product.selectedColor, product.name)}>+</button>
                </div>
            </td>
            <td> ₹{product.price}</td>
            <td> ₹{(product.price * product.quantity).toLocaleString('en-IN')}</td>

            <td>
                <input
                    className="discount-input"
                    type="number"
                    name="discount"
                    value={`${discount1}`}
                    onChange={handleDiscountChangeNew}
                    style={{ width: "50px", marginLeft: "2px" }}
                    min="0"
                    max="100"
                    onInput={(e) => {
                        if (e.target.value < 0) {
                            e.target.value = 0;
                        } else if (e.target.value > 100) {
                            e.target.value = 100;
                        }
                    }}
                />
            </td>
            <td className="cart-summary-amount">₹{totalPrice1.toFixed(2)}</td>
            <td>
                <button
                    className="cart-remove-btn"
                    onClick={() => handleRemoveProduct(product.id, product.selectedColor, product.name)}
                >
                    <RxCross2 />
                </button>
            </td>
        </tr>
    )
}

export default ProductRows
